<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Especificação <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-textarea
            size="lg"
            id="especificacao"
            name="especificacao"
            v-model="form.especificacao"
            placeholder="Digite a especificação"
            rows="4"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Tipo <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.tipo"
            :options="form_options.tipo"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Unidade de medida <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.nome_unidade_medida"
            placeholder="Digite a unidade de medida"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Quantidade <span class="text-danger">*</span>
        </template>
        <template #default>
          <KTInputNumeric
            v-model="form.quantidade"
            :precision="2"
            placeholder="Digite a quantidade"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Preço unitário <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-input-group size="lg" :prepend="moeda">
            <KTInputNumeric
              v-model="form.preco_unitario"
              :precision="2"
              placeholder="Digite o preço unitário"
              autocomplete="off"
              :required="!finalizado"
              :disabled="finalizado"
            />
          </b-input-group>
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        :class="{ 'mb-0': form.fonte_financiamento === 2 }"
      >
        <template #label>
          Fonte de financiamento
          <span class="text-danger">*</span>
        </template>
        <template #default>
          <KTSelect
            v-model="form.fonte_financiamento"
            :options="form_options.fonte_financiamento"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
        v-if="form.fonte_financiamento === 1"
      >
        <template #label>
          Situação
          <span class="text-danger">*</span>
        </template>
        <template #default>
          <KTSelect
            v-model="form.situacao"
            :options="form_options.situacao"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipInvestimentoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_investimento: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Investimento Fixo e Pré-Operacional",
        description: `Investimento Fixo e Pré-operacional: Cadastre cada
          item necessário à implementação do projeto. É importante quantificar
          corretamente cada item, bem como utilizar preços definidos por
          orçamentos formais. O investimento total será a soma do investimento
          fixo e investimento pré-operacional.`
      },
      form: {},
      form_options: {
        tipo: [
          {
            value: 1,
            text: "Terrenos - Investimento Fixo"
          },
          {
            value: 2,
            text: "Edificações - Investimento Fixo"
          },
          {
            value: 3,
            text: "Instalações - Investimento Fixo"
          },
          {
            value: 4,
            text: "Máquinas e Equipamentos - Investimento Fixo"
          },
          {
            value: 5,
            text: "Móveis e Utensílios - Investimento Fixo"
          },
          {
            value: 6,
            text: "Veículos - Investimento Fixo"
          },
          {
            value: 7,
            text: "Computadores e Periféricos - Investimento Fixo"
          },
          {
            value: 8,
            text: "Veículos de Mercadorias - Investimento Fixo"
          },
          {
            value: 9,
            text: "Semoventes - Investimento Fixo"
          },
          {
            value: 10,
            text: "Serviço de Terceiros Pessoa Física - Investimento Pré-operacional"
          },
          {
            value: 11,
            text: "Serviço de Terceiros Pessoa Jurídica - Investimento Pré-operacional"
          },
          {
            value: 12,
            text: "Insumos iniciais - Investimento Pré-operacional"
          },
          {
            value: 13,
            text: "Outras Contrapartidas em Investimento Fixo (Bens Usados)"
          }
        ],
        situacao: [
          { value: 1, text: "Já realizado" },
          { value: 2, text: "A realizar" }
        ],
        fonte_financiamento: [
          { value: 1, text: "Recursos Próprios" },
          { value: 2, text: "Financiamento de Terceiros" }
        ]
      },
      moeda: "",
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/investimento`;
    },
    manual() {
      return {
        session: "investimento",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_investimento;
    },
    parentRoute() {
      return {
        name: "pip_investimento_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_investimento)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].investimento ?? {};
        this.moeda = res[R_GETDATA].moeda;
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_investimento, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
